$mainColor: #009b84;
$darkerHeading: #385b64;
$headerBack: #2e3338;
$altBAckground: #f2f2f2;
$darkText: #3e4653;
$fontChoice: "Proxima Soft", Arial, Helvetica, sans-serif;

* {
  padding: 0px;
  margin: 0px;
}

.body {
  font-family: $fontChoice !important;
}

.App {
  text-align: center;
  overflow-x: hidden;
}

.boldText,
.active {
  font-weight: bold !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a {
  font-family: $fontChoice !important;
}
p {
  font-family: $fontChoice !important;
  font-weight: 500 !important;
  color: $darkText;
  font-size: 17px;
  line-height: 25px;
}

h1 {
  font-size: 45px !important;
  font-weight: 700 !important;
  margin-bottom: 2% !important;
}

//**************************/ components
.content {
  display: grid;
  justify-self: center;
  justify-content: center;
  gap: 50px;
  width: 800px;

  h1 {
    color: $mainColor;
  }
  img {
    justify-self: center;
  }
}

.headerArea {
  height: 50px;
  background-color: $headerBack;
  align-items: center;
  display: grid;
  position: fixed;
  width: 100%;

  .links {
    display: flex;
    justify-content: center;
    align-items: center;

    #last {
      border-right: 0px !important;
    }

    a {
      color: white;
      text-decoration: none;
      border-right: 1px solid white;
      padding: 0px 40px;
      font-size: 18px;
      font-family: $fontChoice;
      font-weight: 400;
      cursor: pointer;
    }
  }
}

.stickyTag {
  display: grid;
  justify-content: right;
  align-items: center;
  float: right;
  margin-top: 130px;
  position: fixed;
  width: 100%;

  .stickyText {
    transform: rotate(-90deg);
    margin-right: -40px;

    a {
      padding: 10px 15px;
      background-color: $mainColor;
      color: white;
      border-radius: 10px 10px 0px 0px;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}

section {
  padding: 70px 50px;
  display: grid;
  justify-content: center;
  gap: 50px;
}

.textBubble {
  padding: 30px 80px;
  border-radius: 100px;
}

.letterBubble {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 1200px;

  .letter {
    font-size: 170px;
    font-family: $fontChoice;
    color: $darkerHeading;
    font-weight: 600;
    width: 20%;
  }

  .wrapper {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;

    .bubble {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0px 50px 0px;
      border-radius: 100px;
      background-color: $altBAckground;
      width: 90%;
      gap: 50px;

      .imgArea {
        width: 200px;
        height: 150px;
      }

      .text {
        text-align: left;

        p {
          font-size: 18px;
        }
      }
    }

    hr {
      width: 84%;
    }
  }
}

//***************************/ page sections

#welcome {
  display: grid;
  justify-content: center;

  .one {
    display: grid;
    justify-items: center;
    gap: 50px;
    img {
      padding-top: 80px;
      width: 50%;
    }
  }

  h1 {
    color: $mainColor;
    padding-top: 80px;
  }
}
#why {
  background-color: $altBAckground;
}

#what {
}

#who {
  background-color: $altBAckground;
}

#using {
  .side-by-side {
    width: 1050px;
    display: flex;
    align-items: center;
    gap: 60px;
    img {
      width: 30%;
    }
  }
}

#results {
  background-color: $altBAckground;
}

#credits {
  padding: 0px !important;
  margin-top: 2px;
  .content {
    h1,
    img {
      display: none;
    }
    a {
      color: $darkText;
      font-size: 45px !important;
      font-weight: 700 !important;
      margin-bottom: 2% !important;
      margin: 100px 0px 50px !important;
      text-decoration: none;

      &:hover {
        color: $mainColor;
      }
    }
  }
  .footer {
    .footerbanner {
      background-color: $mainColor;
      color: #fff;
      font-weight: 600 !important;
      font-size: 22px !important;
      padding: 5px;
    }
    .footer2 {
      padding: 80px 0px;
      background-color: $altBAckground;
      display: grid;
      gap: 100px;
      .footerSection {
        width: 60%;
        display: grid;
        justify-self: center;

        .footerText {
          font-weight: 600 !important;
          font-size: 22px !important;
          line-height: 30px;
          padding: 5px;
          margin-bottom: 20px;
        }
        .footerImgArea {
          background-color: #fff;
          border-radius: 500px;
        }

        #CIHR {
          display: grid;
          align-items: center;
          justify-self: center;
          width: 85%;
          height: 120px;

          img {
            width: 80%;
            justify-self: center;
            padding-top: 10px;
          }
        }
        #UBC {
          display: grid;
          justify-self: center;
          align-items: center;
          width: 100%;
          .image3 {
            display: grid;
            grid-template-columns: 1fr 2fr 1fr;
            gap: 20px;
            padding: 10px 0px;
            width: 80%;
            justify-self: center;
            justify-items: center;
            align-items: center;
            img {
              width: 80%;
            }
          }
        }
        #AEA {
          display: grid;
          justify-self: center;
          align-items: center;
          width: 85%;
          height: 120px;

          .image2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 60px;
            padding: 10px 0px;
            width: 90%;
            justify-self: center;
            justify-items: center;
            align-items: center;

            img {
              width: 100%;
            }

            .image2in1 {
              display: grid;
              align-items: center;
              grid-template-columns: 1fr 1fr;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 850px) {
  .content {
    width: 100%;
  }
  .headerArea {
    .links {
      a {
        padding: 0px 25px;
        font-size: 16px;
      }
    }
  }

  .letterBubble {
    width: 100% !important;
    display: flex;
    justify-self: center;
    gap: 40px;

    .letter {
      font-size: 140px;
      width: 20%;
    }
    .wrapper {
      width: 90%;
      padding: 0px 10px;
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;

      .bubble {
        display: flex;
        gap: 20px;
        .imgArea {
          width: 35%;
          display: grid;
          align-items: center;

          img {
            height: 150px !important;
            width: 100% !important;
          }
        }
        .text {
          width: 65%;
        }
      }
    }
  }

  #credits {
    .content {
      h1 {
        color: $darkText;
        padding: 120px 50px 0px !important;
      }
    }
    .footer {
      .footerbanner {
        font-size: 19px !important;
      }
      .footer2 {
        gap: 60px;
        .footerSection {
          width: 80%;

          .footerText {
            font-size: 18px !important;
            line-height: 25px;
          }
        }
      }
    }
  }

  #using {
    .side-by-side {
      width: auto;
      gap: 30px;
      img {
        width: 40% !important;
      }
    }
    img {
      width: 80% !important;
    }
  }
}

@media only screen and (max-width: 480px) {
  #welcome {
    .one {
      display: grid;
      justify-items: center;
      gap: 30px !important;
      img {
        width: 50% !important;
      }
    }

    h1 {
      padding-top: 20px;
    }
  }

  section {
    padding: 70px 20px;
    display: grid;
    justify-content: center;
    gap: 30px;
  }

  h1 {
    font-size: 40px !important;
  }

  p {
    font-size: 15px !important;
    line-height: 22px;
  }

  .textBubble {
    padding: 30px 15px;
    border-radius: 50px;
  }

  .headerArea {
    .links {
      a {
        padding: 0px 8px;
        font-size: 12px;
      }
    }
  }

  .stickyTag {
    .stickyText {
      a {
        font-size: 14px;
      }
    }
  }

  .letterBubble {
    width: 100% !important;
    display: grid;
    gap: 20px;
    justify-items: center;

    hr {
      width: 95% !important;
    }

    .letter {
      font-size: 100px;
      width: 100%;
    }

    .bubble {
      padding: 10px 15px !important;
      border-radius: 10px !important;
      width: 100%;
      gap: 20px;
      display: grid;
      justify-items: center;

      img {
        width: 100% !important;
      }

      .text {
        text-align: center;
      }
    }
  }

  #credits {
    border-top: 10px solid $headerBack;

    .content {
      h1 {
        padding: 70px 50px 0px !important;
      }
    }
    .footer {
      .footerbanner {
        font-size: 16px !important;
        padding: 5px 50px !important;
      }
      .footer2 {
        padding: 50px 0px;
        gap: 60px;
        .footerSection {
          width: 90%;

          .footerText {
            font-size: 16px !important;
            line-height: 20px;
            padding: 5px 10px !important;
          }

          #CIHR {
            width: 90%;
            height: 70px;

            img {
              width: 90%;
            }
          }
          #UBC {
            width: 90%;
            .image3 {
              gap: 10px;
              width: 85%;
              img {
                width: 80%;
              }
            }
          }
          #AEA {
            width: 90%;
            height: 70px;

            .image2 {
              gap: 40px;
            }
          }
        }
      }
    }
  }

  #using {
    .side-by-side {
      display: grid;
      justify-items: center;
      img {
        width: 70% !important;
      }
    }
    img {
      width: 90% !important;
    }
  }
}
